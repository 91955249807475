.highlighted {
  background: linear-gradient(90deg, #fff, rgba(56, 161, 105, 0.4));
  padding: 2px 7px;
  border-radius: 5px;
}

.graph-container {
  box-shadow: 5px 4px 30px 14px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;

  .estimated_dates_wrap {
    margin-top: 1rem;

    .current_date {
      width: 50%;
      float: left;
      text-align: left;
      padding-left: 15px;
      padding-bottom: 10px;
    }

    .goal_dates {
      width: 50%;
      float: left;
      text-align: right;
      padding-right: 15px;
      padding-bottom: 10px;
    }

    .current_date span,
    .goal_dates span {
      background: rgba(0, 0, 0, 0.07);
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 0.9rem;
      color: #1e1e1e;
    }
  }

  .svg-graph {
    width: 100%;
    height: auto;

    .verticalYJMM {
      stroke-width: 1.5px;
      stroke-dasharray: 0 3;
    }

    .svgText {
      text-anchor: middle;
      stroke-width: 1.419;
      font-weight: 700;

      &.cSad {
        fill: #339d52;
      }

      &.cHappy {
        fill: #339d52;
      }
    }

    .face_1 {
      transform: matrix(0.75, 0, 0, 0.75, 17.845251, 13.566498);
    }

    .face_2 {
      transform: matrix(0.75, 0, 0, 0.75, 124.76877, 58.71051);
    }
  }

  .svg-humanbody {
    background-color: #1e1e1e;
    padding: 1.5rem;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
}

.pulse {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background: #339d52;
  border: 2px solid #339d52;
  animation: pulse 2s infinite;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 120, 247, 0.4);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(45, 120, 247, 0);
  }
}

.primary-btn {
  background-color: #339d52 !important;
}

@media only screen and (max-width: 786px) {
  .top-header {
    line-height: 2.2rem !important;
  }
}
